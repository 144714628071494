<template>
    <div id="release_component">

        <!-- title of the release update -->
        <CRow id="release_title">
            <CColumn class="titillium-web-semibold-black" id="date_time">
                {{ title }} </CColumn>
        </CRow>

        <!-- loop through all the components updated in a release -->
        <suspense>
            <CRow id="release_updates">
                <ul class="titillium-web-regular-black" >
                    <div v-for="(item,i) in components" v-bind:key="i" class="list_items"> 
                        <li> {{ item.component }} {{ item.docker_image }} </li>
                    </div>
                </ul>
            </CRow>
        </suspense>

        <!-- passes the update id to the individual release page through params -->
        <suspense>
            <CRow> <SeeMoreTextButton v-bind:id="id"/> </CRow>
        </suspense>
        
    </div>
</template>

<script>
    import SeeMoreTextButton from '@/components/buttons/SeeIndividual.vue'

    export default {
        name: 'ReleaseCardNoDate',
        components: {
            SeeMoreTextButton,
        },
        props: { 
            id: {
                type: String,
                default: "Loading...",
                required: true
            },
            title: {
                type: String,
                default: "Loading...",
                required: true
            },
            month: {
                type: String,
                default: "Loading...",
                required: true
            },
            year: {
                type: Number,
                default: 2024,
                required: true
            },
            components: {
                type: Array,
                required: true
            },
        },
        setup(props) {
            // setup() receives props as the first argument.
            console.log(props.id)
        }
    }
</script>

<style scoped>

#release_component {
    background-color: #F6F7F7;

    min-height: 9em;
    margin: 1em;
    border-radius: 2em;
    padding-block: 2em;
    padding-inline: 2em;
    height: auto;
    width: 82%;
    /* border: 5px solid blue; */
}

#release_title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* border: 1px solid blue; */
}

#date_time {
    font-size: 1.5em;
    padding-right: 2.5em;
}

#release_updates {
    display: flex;

    margin-top: 0.5em;
    margin-left: 2em;
    /* border: 1px solid red; */
}

.list_items {
    font-size: 0.95em;
}
</style>