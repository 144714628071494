<template>
    <div id="component_notes_card">

        <CColumn>
            <!-- updated component name -->
            <suspense>
                <CRow class="titillium-web-semibold-black">
                    {{ component.component }} </CRow>
            </suspense>

            <!-- updated component content -->
            <suspense>
                <ul class="titillium-web-regular-black"  id="component_notes_updates">
                    <li class="list_items">
                        {{ component.docker_image }}</li>
                </ul>
            </suspense>
        </CColumn>

        <!-- if release notes exist, provide a release notes button. else, display none -->
        <suspense>
            <CColumn v-if="component.release_notes != ''">
                <ShowReleaseNotesButton v-bind:release_url="component.release_notes"/>
            </CColumn>
        </suspense>

    </div>
</template>

<script>
    import ShowReleaseNotesButton from '@/components/buttons/ShowReleaseNotes.vue'

    export default {
        name: 'ComponentNotesCard',
        components: {
            ShowReleaseNotesButton,
        },  
        props:{ component: Object }
    }
</script>

<style scoped>

#component_notes_card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F6F7F7;

    min-height: 6em;
    height: auto;
    width: 82%;
    margin: 0.4em;
    border-radius: 2em;
    padding-block: 1em;
    padding-inline: 2em;

    /* border: 5px solid blue; */
}

#component_notes_updates {
    display: flex;
    margin-top: 0.2em;
    margin-left: 1em;
}

.list_items {
    font-size: 0.95em;
}

/* on hover animations here */

#component_notes_card:hover {
    background-color: #F4FFE0;
}
</style>