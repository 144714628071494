import http from "../http-common";

class EBSReleasesData {
  getAll() {
    return http.get("/ebs-releases-updates");
  }

  get(id) {
    return http.get(`/ebs-releases-updates/${id}`);
  }

  getData(month, year) {
    
    let params = {
        "month": month,  
        "year": year
    }
    return http.post(`/ebs-releases-updates-search`, params);
  }

}

export default new EBSReleasesData();
