<template>
      <NavBar />
      <SupportButton />

      <div id="monthlypage">
        <HeaderBar />

        <!-- date header -->
        <div id="content_holder">
          <CRow class="titillium-web-semibold-white" id="monthly_title">
            {{ updates.month }} {{ updates.year }} </CRow>
        </div>

        <!-- loop through updates database and displays updates of selected month and year -->
        <div v-for="(update,i) in releasesList" v-bind:key="i" class="monthly_content" >

          <!-- creates a new card component for the update -->
          <ReleaseCardNoDate
            v-bind:id="update.id"                 
            v-bind:title="`Release ` + update.id"
            v-bind:month="update.month"
            v-bind:year="update.year"
            v-bind:components="update.updates" />
        </div>

        <BackButton />
      </div>
</template>
  
<script>
  import NavBar from '@/components/NavBar.vue'
  import HeaderBar from '@/components/Header.vue'
  import ReleaseCardNoDate from '@/components/ReleaseCardNoDate.vue'
  import SupportButton from '@/components/buttons/Support.vue'
  import BackButton from '@/components/buttons/Back.vue'
  import EBSReleasesData from "../data/EBSReleasesData"
  
  export default {
    name: 'MonthlyPage',
    components: {
      NavBar,
      HeaderBar,
      ReleaseCardNoDate,
      SupportButton,
      BackButton,
    },

    data () {
      return {
          selected_month: this.$route.params.month,
          selected_year: this.$route.params.year,
          updates: [],
      }
    },
    methods: {

      getData(selected_month, selected_year) {
        EBSReleasesData.getData(selected_month, selected_year)
          .then(response => {
            this.$nextTick(() =>{
              
              let result = response.data.result
              console.log(result);
              this.updates = result;
            })
          })
          .catch(e => {
            console.log(e);
          });
      },
    },
    mounted() {

      this.getData(this.$route.params.month, this.$route.params.year);
    },
    computed: {
      releasesList() {
        return this.updates
      }
    }
  }
</script>

<style scoped>

#monthlypage {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  width: 100%;
}

#content_holder {
  display: flex;
  flex-direction: column;
  background-color: #008D6C;
  
  padding-block: 0.5em;
  margin-bottom: 1em;
  width: 100%;
  height: auto;
  /* border: 5px solid yellow; */
}

#monthly_title {
  font-size: 34px;
  letter-spacing: 0.03em;
  /* border: 5px solid red; */
}

.monthly_content{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  /* border: 5px solid yellow; */
}
</style>