<template>
    <NavBar />
    <SupportButton />
    <div id="homepage">

      <HeaderBar />

      <!-- latest release update section -->
      <suspense>
        <div id="content_holder">
          <CRow class="titillium-web-semibold-white" id="update_title">
            {{ title }} </CRow>
          <ReleaseCardWithDate
            v-bind:id="latest_update.id"   
            v-bind:title="'Release ' + latest_update.id"
            v-bind:month="latest_update.month"
            v-bind:year="latest_update.year"
            v-bind:components="latest_update.updates" />
        </div>
      </suspense>

      <!-- pagination section -->
      <div id="updates_nav">
        <CRow class="titillium-web-regular-green">
          {{ button_title }} </CRow>
      </div>
      
      <!-- pagination buttons displayed here -->
      <suspense>
        <ul id="pagination">
          <li v-for="i in totalPages" v-bind:key="i"
            @click="updatePageViews(i)"
            :class="{ active: current_page == i }"
          >
            {{ i }} 
          </li>
        </ul>
      </suspense>

      <!-- displays all releases depending on chosen page -->
      <div v-for="(update, i) in paginatedUpdates" v-bind:key="i" id="other_updates">
        <ReleaseCardWithDate 
          v-bind:id="update.id"   
          v-bind:title="'Release ' + update.id"
          v-bind:month="update.month"
          v-bind:year="update.year"
          v-bind:components="update.updates" />
      </div>
   
    </div>
</template>
  
<script>
  import NavBar from '@/components/NavBar.vue'
  import HeaderBar from '@/components/Header.vue'
  import ReleaseCardWithDate from '@/components/ReleaseCardWithDate.vue'
  import SupportButton from '@/components/buttons/Support.vue'
  import EBSReleasesData from "../data/EBSReleasesData"

  // pagination values
  let current_page = 1;
  let pagination_max = 4;

  export default {
    name: 'HomePage',
    components: {
      NavBar,
      HeaderBar,
      ReleaseCardWithDate,
      SupportButton,
    },
    data () {
      return {
          title: 'Latest update',
          button_title: 'Check out other release updates',
          current_page: current_page,
          pagination_max: pagination_max,
          latest_update: [{
            id: "Loading...",
            title: "Loading..."
          }],
          other_updates: [],
      };
    },
    mounted() {
      this.getAllData()
    }, 
    methods: {
      fortmatResponse(res) {
        return JSON.stringify(res, null, 2);
      },
      getAllData() {
        EBSReleasesData.getAll()
          .then(response => {
            this.$nextTick(() =>{
              
              let result = response.data.result;
              this.latest_update = result[0];
              this.other_updates = result.slice(1);
              })
          })
          .catch(e => {
            console.log(e);
          });
      },

      // caller function to update the views of updates
      updatePageViews(pageNumber) {
        this.current_page = pageNumber;
        // console.log(this.current_page)
      }
    }, 
    computed: {
      // returns total number of pages for pagination
      totalPages() {
        return Math.ceil(this.other_updates.length / this.pagination_max);
      },
      // returns an array of the updates already paginated
      paginatedUpdates() {
        const startIndex = (this.current_page - 1) * this.pagination_max;
        const endIndex = startIndex + this.pagination_max;
        return this.other_updates.slice(startIndex, endIndex);
      },

    }, 
  }
</script>

<style scoped>

#homepage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

#content_holder {
  display: flex;
  flex-direction: column;
  background-color: #008D6C;

  height: auto;
  padding-block: 3em;
  width: 100%;
  /* border: 5px solid yellow; */
}

#update_title {
  font-size: 34px;
  letter-spacing: 0.03em;

  margin-top: -1.5em;
  margin-right: auto;
  padding: 0.5em;
  padding-left: 1em;
  /* border: 5px solid red; */
}

#updates_nav {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;

  /* border: 5px solid blue; */
}

#other_updates {
  display: flex;
  flex-direction: column;

  height: auto;
  padding-block: 1em;
  width: 100%;
  /* border: 5px solid yellow; */
}

/* https://www.w3schools.com/howto/howto_css_pagination.asp */

/* Pagination links */

#pagination {
  display: inline-block;
  color: #008D6C;
  border-block: solid #008D6C 0.1em;
  /* border: 5px solid yellow; */
}

#pagination li {
  display: inline-block;
  padding: 0.3em 0.8em;
  border-inline: solid #008D6C 0.1em;
  
  cursor: pointer;
  text-decoration: none;
  transition: background-color .3s;
}

/* Style the active/current link */
#pagination li.active {
  background-color: #008D6C;
  color: white;
}

/* Add a grey background color on mouse-over */
#pagination li:hover:not(.active) {
  background-color: #ddd;
}
</style>